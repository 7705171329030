import type { User } from '~/models/user.server'
import type { LeagueMembership } from '~/models/leagueMembership.server'
import LeagueListItem from '~/components/LeagueListItem/LeagueListItem'
import type { League } from '@prisma/client'
import { Form, Link as BaseLink } from '@remix-run/react'
import Button from '~/components/UIKit/Button'
import Gravatar from '~/components/Gravatar'
import noop from '~/lib/noop'
import Link from '~/components/UIKit/Link'

interface Props {
  user: Pick<User, 'id' | 'name' | 'email' | 'emailMd5'>
  leagueList: Array<
    Pick<LeagueMembership, 'leagueId' | 'role'> &
    { league: Pick<League, 'id' | 'name'> }
  > | undefined
  /** A unique ID for this instance of the menu, so the menu item checkboxes will have unique IDs */
  menuId: string
  onSelect?: () => void
}

function UserMenu({ user, leagueList, menuId, onSelect = noop }: Props) {
  return (
    <>
      <div className="p-4">
        <div className="flex flex-row gap-4 items-center">
          <Gravatar user={user} size={40} />
          <span className="text-xl">{user.name}</span>
        </div>
        <div className="ml-14">
          <Link to="/me" onClick={onSelect}>My account</Link>
        </div>
      </div>

      <BaseLink
        to="/leagues/new"
        className="block p-4 text-xl hover:underline border-t border-violet-400"
        onClick={onSelect}
      >
        + Create league
      </BaseLink>

      {leagueList && leagueList.length === 0 && (
        <p className="p-4">No leagues yet</p>
      )}

      {leagueList && leagueList.length > 0 && (
        <ol>
          {leagueList.map((leagueMembership) => (
            <LeagueListItem
              key={leagueMembership.leagueId}
              league={leagueMembership.league}
              role={leagueMembership.role}
              menuId={menuId}
              onSelect={onSelect}
            />
          ))}
        </ol>
      )}

      <Form action="/logout" method="post" className="p-4" onSubmit={onSelect}>
        <Button type="submit" className="w-full">
          Logout
        </Button>
      </Form>
    </>
  )
}

export default UserMenu
